import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const footerStyle = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '30px', // Fixed height for the footer
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[1],
});

const buttonStyle = (theme) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
});

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={footerStyle(theme)}>
      <Typography variant="body2">
        © 2023 DocsTribe AI. All rights reserved.
      </Typography>
      <Box>
        <Button sx={buttonStyle(theme)}>About Us</Button>
        <Button sx={buttonStyle(theme)}>Terms</Button>
      </Box>
    </Box>
  );
};

export default Footer;