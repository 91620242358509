// Layout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, Box } from '@mui/material';
import theme from '../../theme'; // Adjust path if necessary
import Header from './Header';
import MenuTab from './MenuTab';
import Footer from './Footer';

const layoutContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100vw',
};

const headerMenuContainerStyle = {
  flexShrink: 0,
  width: '100%',
};

const mainContentContainerStyle = {
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
};

const contentStyle = {
  flex: 1,
  overflowY: 'auto',
  padding: 2,
  boxSizing: 'border-box',
};

const footerContainerStyle = {
  flexShrink: 0,
  width: '100%',
  backgroundColor: theme.palette.background.default,
};

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={layoutContainerStyle}>
        <Box sx={headerMenuContainerStyle}>
          <Header />
        </Box>
        <Box sx={mainContentContainerStyle}>
          <MenuTab />
          <Box sx={contentStyle}>
            {children}
          </Box>
        </Box>
        <Box sx={footerContainerStyle}>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;