// storage.js
import * as RoutesConstants from '../constants/RouteConstants';
import * as AuthConstants from '../constants/AuthConstants';


export const ClearLocalStorageAndCache = () => {
  // Clear local storage
  localStorage.clear();

  // Clear the cache if needed
  // redirect to login page
  window.location.href = RoutesConstants.LOGIN_ROUTE

};

export const SetLoginData = (data) => {
  localStorage.setItem(AuthConstants.Token, data.token); // Save the token
};



export default ClearLocalStorageAndCache
