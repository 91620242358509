import React, { createContext, useState, useEffect } from 'react';
import * as AuthConstants from '../constants/AuthConstants'; // Ensure the correct path

export const FeaturesContext = createContext();

export const FeaturesProvider = ({ children }) => {
    const [features, setFeatures] = useState(() => {
        // Initialize from localStorage or default to {}
        return JSON.parse(localStorage.getItem(AuthConstants.Features)) || {};
    });

    // Effect to update localStorage when features change
    useEffect(() => {
        localStorage.setItem(AuthConstants.Features, JSON.stringify(features));
    }, [features]);

    return (
        <FeaturesContext.Provider value={{ features, setFeatures }}>
            {children}
        </FeaturesContext.Provider>
    );
};