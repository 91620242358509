// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import * as AuthConstants from '../constants/AuthConstants';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    // Initialize isLoggedIn state based on localStorage
    const [isLoggedIn, setIsLoggedIn] = useState(
        () => localStorage.getItem(AuthConstants.IsLoggedIn) === 'true'
    );

    // Function to handle login
    const login = () => {
        localStorage.setItem(AuthConstants.IsLoggedIn, 'true');
        setIsLoggedIn(true);
    };

    // Function to handle logout
    const logout = () => {
        localStorage.setItem(AuthConstants.IsLoggedIn, 'false');
        setIsLoggedIn(false);
        // Consider adding logic to clear other related localStorage items here
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};