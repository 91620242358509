import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import ClearLocalStorageAndCache from '../../utils/storage';
import { useTheme } from '@mui/material/styles';

const headerStyle = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: theme.shadows[4],
});

const logoStyle = (theme) => ({
  fontSize: '1.5rem',
  fontWeight: 'bold',
});

const buttonStyle = (theme) => ({
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const Header = () => {
  const theme = useTheme();
  const [userName, setUsername] = useState(''); 

  useEffect(() => {
    // Load user key from localStorage
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUsername(parsedUser.name);
    } else {
      setUsername("Welcome");
    }
  }, []);

  const handleLogout = () => {
    // Implement logout functionality
    ClearLocalStorageAndCache();
  };

  return (
    <Box sx={headerStyle}>
      <Typography sx={logoStyle}>DocsTribe</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        <Typography sx={{ paddingRight: '15px', fontSize: "16px", fontWeight: "600" }}>{userName}</Typography>
        <Button variant="outlined" onClick={handleLogout} sx={buttonStyle}>
          LOGOUT
        </Button>
      </Box>
    </Box>
  );
};

export default Header;