import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';  // Adjust the path if your theme file is located elsewhere
import { AuthProvider } from './context/AuthContext';
import { FeaturesProvider } from './context/FeatureContext';
import { routeConfig } from './routeConfig';  // Make sure this path is correct
import PrivateRoute from './components/PrivateRoute';  // Adjust path as necessary
import Layout from './components/layout/Layout';  // Adjust path as necessary
import { LoginPage } from './lazyImports';  // Ensure this is correctly imported

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <FeaturesProvider>
          <Router>
            <Routes>
              <Route path="/" element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <LoginPage />
                </React.Suspense>
              } />
              {routeConfig.map(({ path, component: Component, featureKey }) => (
                <Route key={path} path={path} element={
                  <PrivateRoute featureKey={featureKey}>
                    <Layout>
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <Component />
                      </React.Suspense>
                    </Layout>
                  </PrivateRoute>
                } />
              ))}
              {/* Redirect to login page if no other routes are matched */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </FeaturesProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;